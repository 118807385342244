import React, { Component } from 'react';
import "./NotFound.css?__remix_sideEffect__";
import { Link } from 'react-router-dom';
import YouTubePlayer from 'react-player/youtube.js';

class NotFound extends Component {
  render() {
    const { status = 404 } = this.props;
    return (
      <div className="error">
        <div className="video-ctnr">
          <YouTubePlayer
            width={'100%'}
            height={'110vh'}
            url={`https://youtu.be/t4Y6v0ouVoA?autoplay=1&controls=0&loop=1&playlist=NulgtJrRfPQ&playsinline=1&mute=1&modestbranding=1`}
            playing={true}
            loop={true}
            playsinline={true}
            muted={true}
            className={'react-player error__video'}
            config={{
              youtube: {
                playerVars: { rel: 0, controls: 0 },
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com'
                }
              }
            }} />

        </div>
        <div className="error__copy yellow bg--purple">
          <h1 className="error__headline">{status}</h1>
          <p className="headline headline--italic">
            Pick yourself up and head back home.
          </p>
          <div className='error-button-container'>
            <Link className="btn btn--yellow" to="/">
              <span className="arrow" />Go Home
            </Link>
            <a className="btn btn--yellow" href={`javascript:history.back()`}>
              <span className="arrow" />Previous Page
            </a>
          </div>
        </div>
      </div>);

  }
}

export default NotFound;